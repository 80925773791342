import React, {FunctionComponent} from 'react';
import {FluidObject} from 'gatsby-image';
import PageWidth from '../PageWidth';
import {Subtitle, Title} from '../../styles/product';
import {
  P,
  SectionWrapper,
  SectionContent,
  SectionImage,
} from './ContentSection.styles';
import {getLocalImageFromFragment} from '../../utils';

interface ContentSectionProps {
  title: string;
  subtitle: string;
  paragraph: string;
  image: {
    title: string;
    fluid: FluidObject;
  };
}

export const ContentSection: FunctionComponent<ContentSectionProps> = ({
  title,
  subtitle,
  paragraph,
  image,
}) => (
  <PageWidth>
    <SectionWrapper>
      <SectionContent>
        <Subtitle>{subtitle}</Subtitle>
        <Title>{title}</Title>
        <P>{paragraph}</P>
      </SectionContent>
      {image && (
        <SectionImage
          fluid={getLocalImageFromFragment(image)}
          title={image.title}
        />
      )}
    </SectionWrapper>
  </PageWidth>
);
