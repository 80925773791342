import styled from 'styled-components';
import Img, {GatsbyImageProps} from 'gatsby-image';
import {Paragraph} from '../../styles/product';
import Colours from '../../constants/colours';

export const SectionWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;

  @media (max-width: 900px) {
    grid-template-columns: 1fr;
  }
`;

export const SectionContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const SectionImage = styled(Img)<GatsbyImageProps>`
  max-width: 90%;
  margin-left: 1rem;
  align-self: flex-start;

  @media (max-width: 950px) {
    margin-top: 1rem;
  }
`;

export const P = styled(Paragraph)`
  color: ${Colours.white};
  margin: 0;
`;
