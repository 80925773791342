import styled from 'styled-components';
import {Paragraph} from '../../styles/page';

export const SpecsHeading = styled.h2``;
export const Specs = styled.div``;
export const Spec = styled.div`
  display: flex;
`;

export const SpecDetail = styled(Paragraph)`
  margin-bottom: 0;
`;
