import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {graphql, navigate} from 'gatsby';
import {osName, BrowserView, MobileView} from 'react-device-detect';
import BackgroundImage from 'gatsby-background-image';
import {PageProps} from '../types';
import {Layout} from '../components/Layout';
import SEO from '../components/SEO';
import Colours from '../constants/colours';
import {ContentSection} from '../components/ContentSection';
import PageWidth from '../components/PageWidth';
import {Paragraph} from '../styles/product';
import {
  getApiUrlFromData,
  getBuildEnvFromData,
  getImageFromData,
  getPageData,
} from '../utils';
import {AmpifyStudioPageQuery} from './__generated__/AmpifyStudioPageQuery';
import PlatformDownloadButton, {
  OperatingSystem,
} from '../components/PlatformDownloadButton';
import {Button, ButtonVariant} from '../components/Button';
import {Spacer, Separator} from '../styles/page';
import {SystemRequirements} from '../components/SystemRequirements';
import {useAuth} from '../services/auth';
import {AudioPlayer} from '../components/AudioPlayer';
import {BUILD_ENV_PROD} from '../constants/apps';
import {
  sendAnalyticsEvent,
  sendStudioDownloadEvent,
  sendStudioDownloadPromptEvent,
} from '../utils/countly';
import {PackListPacksInner as Pack} from '@focusrite-novation/ampify-api';
import {PlayerWrapper} from '../components/AudioPlayer/AudioPlayer.styles';
import {CatalogueApi} from '@focusrite-novation/ampify-api';
import {createApi} from '../api';
import DownloadModal from '../components/DownloadModal';

type StudioPageProps = PageProps<AmpifyStudioPageQuery>;

const Studio = (props: StudioPageProps) => {
  const data = getPageData(props.data);
  const buildEnv = getBuildEnvFromData(props.data);
  const apiUrl = getApiUrlFromData(props.data);
  const [previewPacks, setPreviewPacks] = useState([]);
  const {isSubscriber} = useAuth();
  const headerImage = getImageFromData(data, 'headerImage');
  const [selectedOS, setSelectedOS] = React.useState<OperatingSystem>(null);

  const handlePackPreviewed = (pack: Pack) => {
    sendAnalyticsEvent('pack-previewed', {
      page: 'ampify-studio',
      pack: pack.name,
    });
  };

  useEffect(() => {
    const fetchPackData = async () => {
      const groupId =
        buildEnv === BUILD_ENV_PROD
          ? '0f3c2d06-2a51-4978-8084-56bf96be422b'
          : '3ef69397-98ba-4e3b-bfce-49db47ce2b1a';

      const api = createApi(CatalogueApi, apiUrl);
      const response = await api.groupPackList(groupId);

      if (response.data.packs.length > 0) {
        setPreviewPacks(response.data.packs);
      }
    };

    fetchPackData();
  }, []);

  const download = (platform: OperatingSystem) => {
    sendStudioDownloadEvent(platform.os);
    window.location.assign(platform.url);
    setSelectedOS(null);
  };

  const showDownloadModal = (platform: OperatingSystem) => {
    sendStudioDownloadPromptEvent(platform.os);
    setSelectedOS(platform);
  };

  return (
    <Layout>
      <SEO title={data.pageTitle} />
      <HeroWrapper fluid={headerImage}>
        <PageWidth>
          <HeroContent>
            <HeroTitle>
              Ampify <span style={{color: Colours.blue[500]}}>Studio</span>.
            </HeroTitle>
            <BrowserView>
              <HeroParagraph>{data.heroParagraph}</HeroParagraph>
              <Actions>
                <PlatformDownloadButton
                  os={osName}
                  handleClick={(os: OperatingSystem) => showDownloadModal(os)}
                />
                {!isSubscriber && (
                  <Button
                    variant={ButtonVariant.WHITE_OUTLINE}
                    onClick={() => navigate('/subscription/plans')}
                    data-test-id="premium-button-top"
                  >
                    {data.premiumButton}
                  </Button>
                )}
              </Actions>
            </BrowserView>

            <MobileView>
              <HeroParagraph>{data.heroParagraph}</HeroParagraph>
              <HeroParagraph>
                Please visit this page on your Mac or Windows device to download
                Ampify Studio for free.
              </HeroParagraph>
            </MobileView>
          </HeroContent>
        </PageWidth>
      </HeroWrapper>
      <BlueSection>
        <ContentSection
          subtitle={data.blueSectionLabel}
          title={data.blueSectionTitle}
          paragraph={data.blueSectionParagraph}
          image={data.blueSectionImage}
        />
      </BlueSection>
      <GreenSection>
        <ContentSection
          subtitle={data.greenSectionLabel}
          title={data.greenSectionTitle}
          paragraph={data.greenSectionParagraph}
          image={data.greenSectionImage}
        />
      </GreenSection>

      {previewPacks.length > 0 && (
        <PlayerWrapper>
          <PageWidth wide>
            <AudioPlayer
              title="Latest soundpacks in Ampify Studio"
              shadow={true}
              packs={previewPacks}
              onPackPreviewed={handlePackPreviewed}
            />
          </PageWidth>
        </PlayerWrapper>
      )}

      <RedSection>
        <ContentSection
          subtitle={data.redSectionLabel}
          title={data.redSectionTitle}
          paragraph={data.redSectionParagraph}
          image={data.redSectionImage}
        />
      </RedSection>
      <YellowSection>
        <ContentSection
          subtitle={data.yellowSectionLabel}
          title={data.yellowSectionTitle}
          paragraph={data.yellowSectionParagraph}
          image={data.yellowSectionImage}
        />
      </YellowSection>

      <DownloadSection>
        <PageWidth>
          <SystemRequirements
            requirements={{
              macOS: data.systemRequirementsMacOs,
              windows: data.systemRequirementsWindows,
              other: data.systemRequirementsOther,
            }}
          />
          <Spacer />
          <Spacer />
          <Separator />
          <Spacer />
          <Spacer />
          <P>{data.downloadSectionText}</P>
          <BrowserView>
            <Actions>
              <PlatformDownloadButton
                os={osName}
                handleClick={(os: OperatingSystem) => showDownloadModal(os)}
              />
              {!isSubscriber && (
                <Button
                  data-test-id="premium-button-bottom"
                  onClick={() => navigate('/subscription/plans')}
                  variant={ButtonVariant.BLACK_OUTLINE}
                >
                  {data.premiumButton}
                </Button>
              )}
            </Actions>
          </BrowserView>
          <MobileView>
            <P>
              Ampify Studio is currently only available on desktop. Please visit
              this page on your Mac or Windows device to download Ampify Studio
              for free.
            </P>
          </MobileView>
        </PageWidth>
      </DownloadSection>
      {selectedOS !== null && (
        <DownloadModal
          handleDownload={() => download(selectedOS)}
          handleClose={() => setSelectedOS(null)}
          systemRequirements={{
            requirements: {
              macOS: data.systemRequirementsMacOs,
              windows: data.systemRequirementsWindows,
              other: data.systemRequirementsOther,
            },
          }}
        />
      )}
    </Layout>
  );
};

const Section = styled.section`
  padding: 8rem 0;
  color: ${Colours.white};
`;

const BlueSection = styled(Section)`
  background-color: ${Colours.blue[500]};
`;

const GreenSection = styled(Section)`
  background-color: ${Colours.green};
`;

const YellowSection = styled(Section)`
  background-color: ${Colours.yellow};
`;

const RedSection = styled(Section)`
  background-color: ${Colours.redBackground};
`;

const HardwareSection = styled(BlueSection)``;

const DownloadSection = styled.section`
  padding: 4rem 0;
  color: ${Colours.black};
  background-color: ${Colours.grey[100]};
`;

const P = styled(Paragraph)`
  margin-bottom: 2rem;
`;

const HeroTitle = styled.h1`
  font-size: 4rem;
  margin-bottom: 2rem;
`;

const HeroContent = styled.div`
  max-width: 60%;
  @media (max-width: 900px) {
    max-width: 100%;
  }
`;

const HeroParagraph = styled(Paragraph)`
  color: ${Colours.white};
  line-height: 2.2rem;
`;

const HeroWrapper = styled(BackgroundImage)`
  display: flex;
  flex-direction: column;
  height: 60vh;
  background-color: ${Colours.black};
  color: ${Colours.white};

  @media (max-width: 600px) {
    height: 80vh;
  }
`;

const Actions = styled.div`
  display: flex;
  margin-top: 2rem;

  & button {
    margin-right: 1rem;
  }

  @media (max-width: 600px) {
    flex-direction: column;

    & > button,
    & > div {
      margin-top: 1rem;
    }
  }
`;

export const pageQuery = graphql`
  query AmpifyStudioPageQuery {
    en: allContentfulAmpifyWebStudioCustom(
      filter: {node_locale: {eq: "en-GB"}}
    ) {
      edges {
        node {
          enabled
          metaKeywords
          pageTitle
          heroParagraph
          premiumButton
          headerImage {
            ...AmpifyHeaderImage
          }
          blueSectionLabel
          blueSectionTitle
          blueSectionParagraph
          blueSectionImage {
            ...AmpifyBodyImage
          }
          greenSectionLabel
          greenSectionTitle
          greenSectionParagraph
          greenSectionImage {
            ...AmpifyBodyImage
          }
          redSectionLabel
          redSectionTitle
          redSectionParagraph
          redSectionImage {
            ...AmpifyBodyImage
          }
          yellowSectionLabel
          yellowSectionTitle
          yellowSectionParagraph
          yellowSectionImage {
            ...AmpifyBodyImage
          }
          hardwareLabel
          hardwareTitle
          hardwareParagraph
          hardwareImage {
            ...AmpifyBodyImage
          }
          downloadSectionText
          downloadSectionButton
          systemRequirementsMacOs
          systemRequirementsWindows
          systemRequirementsOther
        }
      }
    }
    site {
      siteMetadata {
        apiUrl
        buildEnv
      }
    }
  }
`;

export default Studio;
