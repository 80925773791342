import React, {FunctionComponent} from 'react';
import {
  Specs,
  Spec,
  SpecsHeading,
  SpecDetail,
} from './SystemRequirements.styles';
import {Spacer} from '../../styles/page';

export interface SystemRequirementProps {
  requirements: {
    macOS: string;
    windows: string;
    other: string;
  };
}

export const SystemRequirements: FunctionComponent<SystemRequirementProps> = ({
  requirements,
}) => (
  <>
    <SpecsHeading>Minimum System Requirements</SpecsHeading>
    <Spacer />
    <Specs>
      <Spec>
        <SpecDetail>{requirements.windows}</SpecDetail>
      </Spec>
      <Spec>
        <SpecDetail>{requirements.macOS}</SpecDetail>
      </Spec>
      <Spec>
        <SpecDetail>{requirements.other}</SpecDetail>
      </Spec>
    </Specs>
  </>
);
